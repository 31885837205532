import { createAsyncThunk } from '@reduxjs/toolkit'
import { ORDER_CONFIGS } from '../../../configs/order'
import { ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE, SUCCESS_MSG_PREFIX } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import cartService from '../../../foundation/apis/transaction/cart.service'
import { IProduct } from '@typesApp/product'
import { sendSuccessMessage } from '../../success/slice'
import { toggleAddContactLensesToCartError, toggleAddSubscriptionToCartError } from '../../product/slice'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'
import { ContactLensPayload, PrescriptionFormData } from '@typesApp/prescription'
import { AxiosError } from 'axios'
import { SubscriptionInfo } from '@typesApp/subscription'
import { SUBSCRIPTION_ERROR_KEYS } from '@views/Subscription/constants'

type OrderItem = {
  orderItemId: string
}

export type CartOrder = {
  orderId: string
  orderItem: OrderItem[]
  resourceName: string
  prescription?: PrescriptionFormData
}

export type ContactLensesAddItemArgs = {
  contractId?: string
  callback?: (order?: CartOrder) => void
  product?: IProduct
  updateCart?: boolean
  langId: string
  subscriptionInfo?: SubscriptionInfo
  discount?: { amount: string; userSegment: string }
  items:
    | {
        x_contactLens: ContactLensPayload
        quantity: string
      }
    | {}[]
    | null
}

const addContactLenses = createAsyncThunk<any, ContactLensesAddItemArgs>(
  'order/addContactLensItem',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const payload = args
      const addToCartSuccessCallback = args.callback

      const _orderExtendAttributes = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }

      const body = {
        body: {
          orderId: '.',
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          orderItem: args.items,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderExtendAttribute: [_orderExtendAttributes],
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        },
        skipErrorSnackbar: true,
      }

      const { subscriptionInfo } = args
      if (subscriptionInfo?.active) {
        body.body[ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE] =
          `${subscriptionInfo.recurrency?.value}|${subscriptionInfo.recurrency?.interval}`
      }

      const response = await cartService.addOrderItem(body)
      dispatch(
        sendSuccessMessage({
          key: SUCCESS_MSG_PREFIX + 'ITEM_ADD_SUCCESS',
          link: {
            url: CART,
            textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
          },
        })
      )

      addToCartSuccessCallback?.(response?.data)

      return response.data
    } catch (error) {
      const axiosError = error as AxiosError<any>
      const subscriptionError = axiosError?.response?.data.errors?.find(err =>
        SUBSCRIPTION_ERROR_KEYS.includes(err.errorKey)
      )

      if (subscriptionError) {
        dispatch(toggleAddSubscriptionToCartError(subscriptionError.errorKey))
      } else {
        dispatch(toggleAddContactLensesToCartError(true))
      }

      return rejectWithValue(error)
    }
  }
)

export default addContactLenses
